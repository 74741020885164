import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { PageProps } from '../../global/types/page.types';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { SectionLayout } from '../../layout/SectionLayout/SectionLayout';
import { getSession } from '../../data/api/session/get-session';
import {
  MixPanel,
  OpportunityDetailsMicroFrontend,
} from '@securitize/reactjs-mfe';

import './Invest.css';

function buildSidUrl({
  opportunityId,
  type,
}: {
  opportunityId: string;
  type: 'OPP_DETAILS' | 'SIGN_UP';
}): string {
  const sidBaseUrl = process.env.REACT_APP_SID_WEBSITE_BASE_URL;
  const oppDetailsUrl = `${sidBaseUrl}/primary-market/opportunities/${opportunityId}`;

  switch (type) {
    case 'OPP_DETAILS':
      return oppDetailsUrl;
    case 'SIGN_UP':
      return `${sidBaseUrl}/registration?redirectUrl=${encodeURIComponent(
        oppDetailsUrl,
      )}`;
  }
}

export const Invest: React.FC<PageProps> = ({ pageTitle }) => {
  const navigate = useNavigate();
  useDocumentTitle(pageTitle);
  const [isLoading, setIsLoading] = useState(false);

  const { opportunityId } = useParams<{ opportunityId: string }>();

  useEffect(() => {
    if (!opportunityId) {
      return;
    }

    setIsLoading(true);
    getSession()
      .then(() => {
        window.location.replace(
          buildSidUrl({ type: 'OPP_DETAILS', opportunityId }),
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [opportunityId]);

  if (!opportunityId) {
    navigate('/');
    return;
  }

  return (
    <SectionLayout isLoading={isLoading}>
      {/* md:mkt-mt-[-1.25rem] added to remove gap created by MFE between topbar and opp details top section*/}
      <div className="mkt-mx-auto mkt-flex mkt-justify-center mkt-flex-col mkt-gap-[30px] mkt-px-[24px] lg:mkt-px-[64px] xl:mkt-px-0 md:mkt-mt-[-1.25rem]">
        <OpportunityDetailsMicroFrontend
          entryPointUrl={
            process.env.REACT_APP_PRIMARY_MARKET_OPP_DETAILS_MFE_URL ?? ''
          }
          history={createMemoryHistory()}
          userInfo={null}
          basePath="/invest"
          params={{
            basePath: '/invest',
            opportunityId,
            logoutUser: () => null,
            showModal: () => null,
            mixPanel: {} as MixPanel,
            clientData: {
              type: 'SID',
              logo: '',
              name: '',
            },
            externalRoutes: {
              SIGN_UP: buildSidUrl({ type: 'SIGN_UP', opportunityId }),
            },
            theme: 'DARK',
            setHeaderOverrides: () => null,
          }}
        />
      </div>
    </SectionLayout>
  );
};
