import { MktTopBar, MktTopBarNestableItem } from '@securitize/reactjs-mkt-ui';
import {
  TopBarItemModel,
  TopBarLinkModel,
} from '../../data/builderio/top-bar/top-bar-link.model';
import { Link } from 'react-router-dom';
import secPositive from '@securitize/assets-sec-ui/dist/logos/svg/sec-trademark-positive.svg';
import secNegative from '@securitize/assets-sec-ui/dist/logos/svg/sec-trademark-negative.svg';
import { LocationDescriptor } from 'history';

interface TopBarProps {
  topBarLinks: TopBarLinkModel[] | [];
  variant?: 'dark' | 'light' | 'transparent';
  transparentVariant: 'light' | 'dark';
  fixedVariant: 'light' | 'dark' | null;
}

export const TopBar: React.FC<TopBarProps> = ({
  topBarLinks,
  variant = 'dark',
  transparentVariant = 'dark',
  fixedVariant,
}) => {
  const homeItem: MktTopBarNestableItem = topBarLinks
    .filter(({ data }) => data.type === 'home')
    .map(({ data }: TopBarLinkModel, index) => {
      return {
        id: index,
        label: data.label,
        url: data.url,
      };
    })[0];

  const menuItems: MktTopBarNestableItem[] = topBarLinks
    .filter(({ data }) => data.type === 'nav')
    .map(({ data }: TopBarLinkModel, index) => {
      return {
        id: index,
        label: data.label,
        url: data.url,
        subLinks: data.subLinks?.map(
          (subLink: TopBarItemModel, index: number) => {
            return {
              id: index,
              label: subLink.label,
              url: subLink.url,
            };
          },
        ),
      };
    });

  const loginItem: MktTopBarNestableItem = topBarLinks
    .filter(({ data }) => data.type === 'login')
    .map(({ data }: TopBarLinkModel, index) => {
      return {
        id: index,
        label: data.label,
        url: data.url,
      };
    })[0];

  const signUpItem: MktTopBarNestableItem = topBarLinks
    .filter(({ data }) => data.type === 'register')
    .map(({ data }: TopBarLinkModel, index) => {
      return {
        id: index,
        label: data.label,
        url: data.url,
      };
    })[0];

  return (
    <div>
      <MktTopBar
        homeItem={homeItem}
        menuItems={menuItems}
        loginItem={loginItem}
        signUpItem={signUpItem}
        linkComponent={props => (
          <Link {...props} to={props.to as LocationDescriptor} />
        )}
        variant={variant}
        negativeLogo={secNegative}
        positiveLogo={secPositive}
        transparentVariant={transparentVariant}
        fixedVariant={fixedVariant}
      />
    </div>
  );
};
