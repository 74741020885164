import React from 'react';
import { LocalRoutesEnum } from '../../helpers/constants';
import { TopBar } from '../../components/TopBar/TopBar';
import { removeLeadingSlashes } from '../../helpers/navigation.helpers';

export type TopBarProps = Omit<
  React.ComponentProps<typeof TopBar>,
  'topBarLinks'
> & { isSticky: boolean };

interface LocalLayoutConfig {
  topBar: TopBarProps;
}

const cleanedRoutes = removeLeadingSlashes(LocalRoutesEnum);

export const defaultLocalLayoutConfig: LocalLayoutConfig = {
  topBar: {
    variant: 'light',
    transparentVariant: 'dark',
    fixedVariant: null,
    isSticky: true,
  },
};

export const localRoutesLayoutConfig: Map<string, LocalLayoutConfig> = new Map([
  [cleanedRoutes.BLOG, defaultLocalLayoutConfig],
  [cleanedRoutes.PRESS, defaultLocalLayoutConfig],
  [
    cleanedRoutes.INVEST,
    {
      topBar: {
        variant: 'dark',
        transparentVariant: 'light',
        fixedVariant: 'dark',
        isSticky: false,
      },
    },
  ],
]);
