/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo } from 'react';
import { BuilderContent } from '@builder.io/sdk';
import { LoadingPage } from '../../pages/Loading/LoadingPage';
import { MemoizedFooter } from '../../components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { SectionLayout } from '../SectionLayout/SectionLayout';
import { TopBar } from '../../components/TopBar/TopBar';
import { navigationRoutes } from '../../routes/navigation-routes';
import { ErrorPage } from '../../pages/Error/ErrorPage';
import { RedirectUrlModel } from '../../data/builderio/url-redirects/redirect-url-model';
import {
  generateDestinationUrl,
  doesCurrentUrlMatchRedirect,
  routePattern,
} from '../../helpers/navigation.helpers';
import { MktBanner } from '@securitize/reactjs-mkt-ui';
import clsx from 'clsx';
import { useRedirectUrl } from '../../hooks/useRedirectsUrl';
import { scrollToTop } from '../../helpers/utils-helpers';
import { usePageStore } from '../../store/page/page.store';
import {
  TopBarProps,
  defaultLocalLayoutConfig,
  localRoutesLayoutConfig,
} from './local-routes-layout.config';

function getTopBarProps(
  currentUrlPath: string,
  content: BuilderContent | undefined,
): TopBarProps {
  const localRouteMatch = routePattern().exec(currentUrlPath);

  if (localRouteMatch && localRoutesLayoutConfig.has(localRouteMatch[1])) {
    return localRoutesLayoutConfig.get(localRouteMatch[1])!.topBar;
  }

  return {
    variant:
      content?.data?.topbarVariant ?? defaultLocalLayoutConfig.topBar.variant,
    transparentVariant:
      content?.data?.topbarTransparentVariant ??
      defaultLocalLayoutConfig.topBar.transparentVariant,
    fixedVariant: defaultLocalLayoutConfig.topBar.fixedVariant,
    isSticky: defaultLocalLayoutConfig.topBar.isSticky,
  };
}

interface SectionLayoutProps {
  children?: React.ReactNode;
  className?: string;
}
export const PageLayout: React.FC<SectionLayoutProps> = ({
  children,
  className,
}) => {
  const currentUrlPath = useLocation().pathname;

  const {
    getPageContent,
    isPageLoading,
    content,
    footerLinks,
    isFooterLoading,
    getFooterLinks,
    getTopbarLinks,
    isTopBarLoading,
    topBarLinks,
    bannerContent,
    getBannerContent,
    isBannerContentLoading,
  } = usePageStore(state => state);

  useEffect(() => {
    scrollToTop();
  }, [currentUrlPath]);

  const redirectUrl = useRedirectUrl();

  useEffect(() => {
    if (
      !(
        navigationRoutes.some(route => {
          return route.path === currentUrlPath;
        }) || routePattern().test(currentUrlPath)
      )
    ) {
      getPageContent(currentUrlPath || '/');
    }
  }, [currentUrlPath]);

  useEffect(() => {
    Promise.all([getTopbarLinks(), getFooterLinks(), getBannerContent()]);
  }, []);

  const topBarProps = useMemo(
    () => getTopBarProps(currentUrlPath, content),
    [currentUrlPath, content],
  );

  /**
   * We check if we do not have the footer or the topbar content we display the loading page.
   * we also need to check if we already have the redirects url from builder before the first render, so if the user enters a url to be redirected the 404 pages do not flash for a sec
   * @returns Redirected Page
   */
  if (
    isFooterLoading ||
    isTopBarLoading ||
    isBannerContentLoading ||
    !redirectUrl
  ) {
    return <LoadingPage />;
  }

  const isPageUnavailable = !content && !isPageLoading;

  /**
   * we check that the current page does not exist and we do not setup a redirect for a page that was not migrated
   * @returns Redirected Page
   */
  if (
    isPageUnavailable &&
    redirectUrl?.some((redirect: RedirectUrlModel) =>
      doesCurrentUrlMatchRedirect(redirect, currentUrlPath),
    )
  ) {
    const validSourceUrl: RedirectUrlModel | undefined = redirectUrl?.find(
      (redirect: RedirectUrlModel) =>
        doesCurrentUrlMatchRedirect(redirect, currentUrlPath),
    );

    const destinationUrl =
      validSourceUrl && generateDestinationUrl(validSourceUrl);

    if (destinationUrl) {
      window.location.replace(new URL(destinationUrl).href);
      return null;
    }
    return null;
  }

  /**
   * We verify that the page is not local, and that it does not exist in builder either.
   * @returns 404 page
   */
  if (
    isPageUnavailable &&
    !navigationRoutes.some(route => {
      return route.path === currentUrlPath;
    }) &&
    !routePattern().test(currentUrlPath)
  ) {
    return (
      <main className={className}>
        <TopBar
          topBarLinks={topBarLinks}
          transparentVariant="dark"
          fixedVariant={'dark'}
        />
        <ErrorPage />
        <MemoizedFooter footerLinks={footerLinks} />
      </main>
    );
  }

  /**
   * return page
   *  @returns Page
   */
  return (
    <main>
      {bannerContent && (
        <MktBanner
          description={bannerContent.text}
          link={{
            label: bannerContent.link?.label as string,
            url: bannerContent.link?.url as string,
          }}
        />
      )}

      <div className={className} style={{ position: 'relative' }}>
        <div
          className={clsx('mkt-top-0 mkt-left-0 mkt-z-[80000]', {
            'mkt-sticky': topBarProps.isSticky,
            'mkt-relative': !topBarProps.isSticky,
          })}
          id="mkt-top-bar"
        >
          <TopBar topBarLinks={topBarLinks} {...topBarProps} />
        </div>

        <SectionLayout
          className={clsx({
            'mkt-mt-[-88px]': topBarProps.isSticky,
          })}
          isLoading={isPageLoading}
        >
          {children}
        </SectionLayout>
        <MemoizedFooter footerLinks={footerLinks} />
      </div>
    </main>
  );
};
