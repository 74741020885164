export const configs = {
  BUILDER_PUBLIC_API_KEY: process.env.REACT_APP_BUILDER_PUBLIC_API_KEY || '',
  MARKETING_API_BASE_URL: process.env.REACT_APP_MARKETING_API_BASE_URL || '',
  SID_WEBSITE_BASE_URL: process.env.REACT_APP_SID_WEBSITE_BASE_URL || '',
};

export enum BuilderModels {
  // Page models
  PAGE = 'page',
  // Global
  TOP_BAR_LINK = 'top-bar-link',
  FOOTER_LINK = 'footer-link',
  // Blog
  BLOG_ARTICLE = 'blog-article',
  BLOG_AUDIENCE = 'blog-audience',
  BLOG_CONTENT_TYPE = 'blog-content-type',
  BLOG_TAG = 'blog-tag',
  // Testimonial
  TESTIMONIAL = 'testimonial',
  // Offering Table
  OFFERING_GROUP = 'offering-group',
  OFFERING_FEATURE = 'offering-feature',
  // Press
  PRESS_ARTICLE = 'press-article',
  EXTERNAL_PRESS_ARTICLE = 'external-press-article',
  PRESS_AUDIENCE = 'press-audience',
  // Url Redirects
  URL_REDIRECTS = 'url-redirects',
  // Banner model
  BANNER = 'banner',
}

export enum BuilderComponents {
  // Accordion
  ACCORDION = 'Accordion',
  // Banner
  BANNER = 'Banner',
  // Fqs Accordion
  FAQS_ACCORDION = 'Faqs Accordion',
  // Blog
  BLOG_ARTICLE_QUOTE = 'Blog Article Quote',
  BLOG_ARTICLE_SUBTITLE = 'Blog Article Subtitle',
  BLOG_ARTICLE_TEXT = 'Blog Article Text',
  // Markets
  MARKETS_SWITCHER = 'Markets Switcher',
  // Offering Table
  OFFERING_TABLE = 'Offering Table',
  // PopUp Form
  POPUP_FORM = 'Popup Form',
  // Related Articles
  BLOG_ARTICLES_BY_AUDIENCE = 'Blog Articles by Audience',
  BLOG_ARTICLES_BY_ID = 'Blog Articles by ID',
  BLOG_ARTICLES_BY_TAG = 'Blog Articles by Tag',
  // Testimonial
  TESTIMONIAL = 'Testimonial',
  TESTIMONIALS_SLIDER = 'Testimonials Slider',
  // Featured Offer
  FEATURED_OFFER = 'Featured Offer',
  FEATURED_OFFER_BOX = 'Featured Offer Box',
  FEATURED_OFFER_SLIDER = 'Featured Offers Slider',
  // Button
  BUTTON = 'Button',
  // Consent Modal
  PRIMARY_MARKET_CONSENT_MODAL = 'Primary Market Consent Modal',
  // Market Car
  MARKET_CARD = 'Market Card',
}

export const LocalRoutesEnum = {
  BLOG: '/learn/blog',
  PRESS: '/learn/press',
  INVEST: '/invest',
};

export const blogConstants = {
  BLOG_SEARCH_PARAM_NAME: 'search',
};

export const footerConstants = {
  COPYRIGHT: '© 2024 Securitize LLC',
  EMAIL_CONTACT: 'info@securitize.io',
};
