import { INavigationRoutes } from '../global/types/navigation.types';

// Builder wildcard Page
import { Page } from '../pages/Page';

// Blog Pages
import { Blog } from '../pages/Blog';
import { BlogArticle } from '../pages/Blog/BlogArticle';

// Press pages
import { Press } from '../pages/Press';
import { PressArticle } from '../pages/Press/PressArticle';

import { Invest } from '../pages/Invest';

// Local Routes that don´t come from builder
import { LocalRoutesEnum } from '../helpers/constants';

// Navigation Routes to map in router
export const navigationRoutes: INavigationRoutes[] = [
  {
    path: LocalRoutesEnum.BLOG,
    Component: Blog,
    props: {
      pageTitle: 'Blog',
    },
  },
  {
    path: `${LocalRoutesEnum.BLOG}/:articleId`,
    Component: BlogArticle,
  },
  {
    path: LocalRoutesEnum.PRESS,
    Component: Press,
    props: {
      pageTitle: 'Press',
    },
  },
  {
    path: `${LocalRoutesEnum.PRESS}/:articleId`,
    Component: PressArticle,
  },
  {
    path: `${LocalRoutesEnum.INVEST}/:opportunityId`,
    props: {
      pageTitle: 'Invest in Primary Market',
    },
    Component: Invest,
  },
  {
    path: '*',
    Component: Page,
  },
];
